import { Link } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCoffee } from '@fortawesome/free-solid-svg-icons'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faTwitter, faDiscord } from '@fortawesome/free-brands-svg-icons';
library.add(faTwitter, faDiscord);

export default function Navbar(){
    return (
        <nav class="navbar navbar-expand-lg navbar-dark bg-dark">
          <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavAltMarkup" aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
          </button>

          <div class="navbar-brand">
            <a class="btn btn-light btn-social mx-2" href="https://twitter.com/lnsstudios_" aria-label="Twitter"><FontAwesomeIcon icon={faTwitter} /></a>
            <a class="btn btn-light btn-social mx-2" href="https://discord.gg/GgvtDQrqhs" aria-label="Discord"><FontAwesomeIcon icon={faDiscord} /></a>
          </div>

          <div class="collapse navbar-collapse" id="navbarNavAltMarkup">
            <div class="navbar-nav">
              <a class="nav-item nav-link active" href="/">LNS Studios Home</a>
              <a class="nav-item nav-link"><Link to="/vanlife">VanLife Game</Link></a>
              <a class="nav-item nav-link"><Link to="/nft-project">NFT Project</Link></a>
              <a class="nav-item nav-link" href="https://late-night-samurai-studios.gitbook.io/vanlife-whitepaper/" target="_blank">Our Whitepaper</a>
            </div>
          </div>

        </nav>
    )
}