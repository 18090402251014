import React, { Component } from "react";

import Navbar from '../navbar/Navbar.js';
import Projects from '../projects/Projects.js';
import AboutUs from '../about-us/AboutUs.js';
import OurTeam from '../our-team/OurTeam.js';
import ContactUs from '../contact-us/ContactUs.js';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCoffee } from '@fortawesome/free-solid-svg-icons'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faTwitter, faDiscord } from '@fortawesome/free-brands-svg-icons';
library.add(faTwitter, faDiscord);

export default function Home(){
    return(
        <body id="page-top">

                <Navbar />

                <header class="masthead">
                    <div class="container">
                        <div class="masthead-subheading">Late Night Samurai Studios</div>
                        <div class="masthead-heading text-uppercase">Exploration and adventure integrated with technology</div>
                        <a class="btn btn-primary btn-xl text-uppercase" href="#our-projects">Show Me More</a>
                    </div>
                </header>

                <section class="page-section bg-light" id="our-projects">
                    <Projects />
                </section>

                <section class="page-section" id="about">
                    <AboutUs />
                </section>

                <section class="page-section bg-light" id="team">
                    <OurTeam />
                </section>

                <section class="page-section" id="contact">
                    <ContactUs />
                </section>

                <footer class="footer py-4">
                    <div class="container">
                        <div class="row align-items-center">
                            <div class="col-lg-4 text-lg-start">Copyright &copy; Late Night Samurai Studios 2022</div>
                            <div class="col-lg-4 my-3 my-lg-0">
                                <a class="btn btn-dark btn-social mx-2" href="https://twitter.com/lnsstudios_" aria-label="Twitter"><FontAwesomeIcon icon={faTwitter} /></a>
                                <a class="btn btn-dark btn-social mx-2" href="https://discord.gg/GgvtDQrqhs" aria-label="Discord"><FontAwesomeIcon icon={faDiscord} /></a>
                            </div>
                            <div class="col-lg-4 text-lg-end">
                                <a class="link-dark text-decoration-none me-3" href="#"></a>
                                <a class="link-dark text-decoration-none" href="#"></a>
                                Adventure Awaits!
                            </div>
                        </div>
                    </div>
                </footer>

            </body>
    )
}