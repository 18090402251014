import React, { Component } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCoffee } from '@fortawesome/free-solid-svg-icons'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faTwitter } from '@fortawesome/free-brands-svg-icons';
library.add(faTwitter);

export default function OurTeam() {
    return(
        <div class="container">
            <div class="text-center">
                <h2 class="section-heading text-uppercase">The Samurai</h2>
                <h3 class="section-subheading text-muted">Meet our awesome team</h3>
            </div>
            <div class="row">
                <div class="col-lg-6">
                    <div class="team-member">
                        <img class="mx-auto rounded-circle" src={require('../../assets/team/0xGreens.jpg')} />
                        <h4>0xGreens</h4>
                        <p class="text-muted">Cofounder/Lead Developer</p>
                        <a class="btn btn-dark btn-social mx-2" href="https://twitter.com/0xGreens" aria-label="0xGreens Twitter Profile"><FontAwesomeIcon icon={faTwitter} /></a>

                    </div>
                </div>
                <div class="col-lg-6">
                    <div class="team-member">
                        <img class="mx-auto rounded-circle" src={require('../../assets/team/megaman.jpg')} />
                        <h4>0xMegaman</h4>
                        <p class="text-muted">Cofounder/Marketing Lead </p>
                        <a class="btn btn-dark btn-social mx-2" href="https://twitter.com/0xMegaman" aria-label="0xMegaman Twitter Profile"><FontAwesomeIcon icon={faTwitter} /></a>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-8 mx-auto text-center">
                    <p class="large text-muted">We are entrepreneur dreamers with tons of ambition, and Dad-bods by day.</p>
                </div>
            </div>
        </div>
    )
}