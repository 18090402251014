import { Link } from 'react-router-dom';

export default function Projects() {
    return(
         <div class="container">

            <div class="text-center">
                <h2 class="section-heading text-uppercase">Our Projects</h2>
                <h3 class="section-subheading text-muted">Projects we are actively developing</h3>
            </div>

            <div class="row">
                <div class="col-lg-6 col-sm-6 mb-4">
                    <div class="portfolio-item">
                        <Link to="/vanlife">
                            <img class="img-fluid" src={require('../../assets/vanlife_2.png')} alt="..." />
                        </Link>
                        <div class="portfolio-caption">
                            <div class="text-center">
                                <div class="portfolio-caption-heading"><Link to="/vanlife">VanLife: Life On The Road</Link></div>
                                    <small>(click image for more info)</small>
                                    <br/><br/>
                                <div class="portfolio-caption-subheading text-muted">An immersive 3D VanLife adventure
                                game with cryptocurrency integrations on the Avalanche Blockchain launching on the
                                Epic Games Marketplace.</div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-lg-6 col-sm-6 mb-4">
                    <div class="portfolio-item">
                        <Link to="/nft-project">
                            <img class="img-fluid" src={require('../../assets/avax.jpg')} alt="..." />
                        </Link>
                        <div class="portfolio-caption">
                            <div class="text-center">
                                <div class="portfolio-caption-heading"><Link to="/nft-project">Avalanche NFT Project</Link></div>
                                    <small>(click image for more info)</small>
                                    <br/><br/>
                                <div class="portfolio-caption-subheading text-muted">An NFT project launching on the
                                Avalanche blockchain integrated with our first major game title, VanLife: Life On The
                                Road.  Become an early supporter of the project, and unlock future in-game items.</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}