export default function ContactUs() {
    return(
        <div class="container">
            <div class="text-center">
                <h2 class="section-heading text-uppercase">Contact Us</h2>
                <h3 class="section-subheading text-muted">Questions, Comments, Lovenotes, etc..</h3>
            </div>

            <div class="text-center"><a class="btn btn-primary btn-xl text-uppercase" href="https://discord.gg/GgvtDQrqhs" role="button">Send Message</a></div>
        </div>
    )
}