import './App.css';
import React, { Component } from "react";
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';

import Navbar from './components/navbar/Navbar.js';
import Home from './components/home/Home.js';
import VanLife from './components/vanlife/VanLife.js';
import NftProject from './components/nft-project/NftProject.js';

export default function App() {
  return (
  <>
    <Router>
        <div className="App">
            <Routes>
                <Route exact path='/' element={< Home />}></Route>
                <Route exact path='/vanlife' element={< VanLife />}></Route>
                <Route exact path='/nft-project' element={< NftProject />}></Route>
            </Routes>
        </div>
    </Router>
   </>
  )
}
