import Navbar from '../navbar/Navbar.js';
import { Link } from 'react-router-dom';

export default function NftProject(){
    return(
        <>
            <Navbar />

            <div class="container">
                <br />
                <div class="jumbotron">
                    <h2 class="section-heading text-uppercase">Avalanche Blockchain NFT Project</h2>
                    <h3 class="section-subheading text-muted">Integration with VanLife Game</h3>
                    <hr class="my-4" />

                    <div class="col-lg-6 col-sm-6 mb-4">
                        <div class="portfolio-item">
                            <img class="img-fluid" src={require('../../assets/avax.jpg')} alt="..." />
                        </div>
                    </div>

                    <div class="portfolio-caption-subheading text-muted">
                        <p>
                            <a href="https://late-night-samurai-studios.gitbook.io/vanlife-whitepaper/" target="_blank">Project Whitepaper</a>
                        </p>
                                            
                        <p>
                            The NFT Project will serve as a means to launch a genesis NFT collection on the Avalanche
                            Blockchain, gain early project support for further development of our
                            &nbsp;<Link to="/vanlife">VanLife game</Link>, and allow players to unlock exclusive in-game
                             assets and features.
                        </p>

                        <h4>Project Roadmap</h4>
                        <p>
                            <i>Note: Roadmap is subject to change at any time as we work on development efforts</i>
                        </p>

                        <p>
                            <b>Q1 2023 - by end of March</b>
                            <ul>
                                <li><strike>Create Twitter Accounts / Begin Marketing</strike></li>
                                <li><strike>Develop Treasury / Financial Plan</strike></li>
                                <li><strike>Create Initial LNS Studio Website</strike></li>
                                <li><strike>Create Initial Project Roadmap</strike></li>
                                <li><strike>Create Discord Server with Bots</strike></li>
                                <li><strike>Release Public Whitepaper</strike></li>
                            </ul>
                        </p>

                        <p>
                            <b>Q2 2023 - by end of June</b>
                            <ul>
                                <li>Sneak Peak of Fishing Experience</li>
                                <li>Preview of Vehicles and Features</li>
                                <li>Whitelist Building With Partners</li>
                                <li>Website Enhancements</li>
                            </ul>
                        </p>

                        <p>
                            <b>Q3 2023 - by end of September</b>
                            <ul>
                                <li>NFT Launch 1: Genesis Mint 1000 Vehicles</li>
                                    <ul>
                                        <li>Vehicles will have traits that unlock in-game features</li>
                                        <li>Fully Customizable Cosmetics</li>
                                        <li>Non NFT players will earn similar assets by spending in-game currency</li>
                                    </ul>
                                <li>Airdrop of Special NFT Fishing Pole</li>
                                    <ul>
                                        <li>Exclusive enhanced fishing pole in-game item grant at initial launch</li>
                                    </ul>
                                <li>NFT Launch 2: Mint 2000-4000 Vehicles</li>
                                    <ul>
                                        <li>Some Customizable Cosmetics</li>
                                        <li>Note: Launch 1 NFT Vehicle holders will retain full customizable cosmetic ability</li>
                                    </ul>
                                <li>Early Access to All Future Launches Including Exclusive Vehicles</li>
                                <li>Discounted Future Launches</li>
                                <li>Alpha / Early Access Game Launch on Epic Games Marketplace</li>
                            </ul>
                        </p>

                        <p>
                            We are excited to share more details and future plans as we continue to develop the
                            project. &nbsp;Please visit this website for future updates
                            and consider joining our <a href='https://discord.gg/GgvtDQrqhs'>Discord</a> to connect
                            with us and stay up to date on the latest news. We would love to hear from you.
                        </p>
                    </div>
                </div>
            </div>
        </>
    )
}