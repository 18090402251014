export default function AboutUs() {
    return(
        <div class="container">
            <div class="text-center">
                <h2 class="section-heading text-uppercase">LNS Studios</h2>
                <h3 class="section-subheading text-muted">About Us</h3>
                <p>
                    Late Night Samurai Studios is a video game development company bringing the best games that no one else is making for ultimately
                    a wide variety of platforms.  By bringing in the latest and greatest technologies available we are able to
                    bring the best quality experiences for our players which will always remain our first priority.
                    Our software integrates with existing services that will both excite our players
                    and make things as easy as possible so you can relax and focus on gaming.
                </p>
            </div>
            <br /><br />

            <ul class="timeline">
                <li>
                    <div class="timeline-image"><img class="rounded-circle img-fluid" src={require('../../assets/about-us/www_2.png')} alt="..." /></div>
                    <div class="timeline-panel">
                        <div class="timeline-heading">
                            <h4>May 2020</h4>
                            <h4 class="subheading">Sword Up</h4>
                        </div>
                        <div class="timeline-body"><p class="text-muted">LNS Studios is born and website domains are
                        purchased. Work on a since-shelved initial Oculus Rift VR game project begins development.</p></div>
                    </div>
                </li>
                <li class="timeline-inverted">
                    <div class="timeline-image"><img class="rounded-circle img-fluid" src={require('../../assets/about-us/brainstorm.jpeg')} alt="..." /></div>
                    <div class="timeline-panel">
                        <div class="timeline-heading">
                            <h4>June 2021</h4>
                            <h4 class="subheading">Ideas Aplenty</h4>
                        </div>
                        <div class="timeline-body"><p class="text-muted">The team begins to brainstorm ideas and concepts involving
                        cryptocurrency in a gamified application.</p></div>
                    </div>
                </li>
                <li>
                    <div class="timeline-image"><img class="rounded-circle img-fluid" src={require('../../assets/about-us/refocus.jpeg')} alt="..." /></div>
                    <div class="timeline-panel">
                        <div class="timeline-heading">
                            <h4>December 2022</h4>
                            <h4 class="subheading">Refocus Time</h4>
                        </div>
                        <div class="timeline-body"><p class="text-muted">The team decides to refocus efforts on creating
                        a 3D adventure game to integrate cryptocurrency and NFT's to expand our brand.</p></div>
                    </div>
                </li>
                <li class="timeline-inverted">
                    <div class="timeline-image">
                        <h4>
                            And More<br />
                            Good Stuff<br />
                            To Come!
                        </h4>
                    </div>
                </li>
            </ul>
        </div>
    )
}