import Navbar from '../navbar/Navbar.js';
import { Link } from 'react-router-dom';

export default function VanLife() {
    return(
        <>
            <Navbar />

            <div class="container">
                <br />
                <div class="jumbotron">
                    <h2 class="section-heading text-uppercase">VanLife</h2>
                    <h3 class="section-subheading text-muted">'Life On The Road'</h3>
                    <hr class="my-4" />
                    <br />

                    <div id="carouselCaptions" class="carousel slide carousel-fade" data-ride="carousel">
                      <ol class="carousel-indicators">
                        <li data-target="#carouselCaptions" data-slide-to="0" class="active"></li>
                        <li data-target="#carouselCaptions" data-slide-to="1"></li>
                        <li data-target="#carouselCaptions" data-slide-to="2"></li>
                        <li data-target="#carouselCaptions" data-slide-to="3"></li>
                      </ol>
                      <div class="carousel-inner">
                        <div class="carousel-item active">
                          <img src={require('../../assets/vanlife_2.png')} class="d-block w-100" alt="..." />
                          <div class="carousel-caption d-none d-md-block">
                            <h5>The Dream</h5>
                            <p>Fully immerse the player into a mobile lifestyle full of adventure.</p>
                          </div>
                        </div>
                        <div class="carousel-item">
                          <img src={require('../../assets/dock.png')} class="d-block w-100" alt="..." />
                          <div class="carousel-caption d-none d-md-block">
                            <h5>Early Environmental Fishing Pond Concept</h5>
                            <p>Imagery subject to change as we work to develop the game.</p>
                          </div>
                        </div>
                        <div class="carousel-item">
                          <img src={require('../../assets/pond.png')} class="d-block w-100" alt="..." />
                          <div class="carousel-caption d-none d-md-block">
                            <h5>Early Environmental Fishing Pond Concept</h5>
                            <p>Imagery subject to change as we work to develop the game.</p>
                          </div>
                        </div>
                        <div class="carousel-item">
                          <img src={require('../../assets/fish.png')} class="d-block w-100" alt="..." />
                          <div class="carousel-caption d-none d-md-block">
                            <h5>Fishing Pond Fish Concept Models</h5>
                            <p>Imagery subject to change as we work to develop the game.</p>
                          </div>
                        </div>
                      </div>
                      <button class="carousel-control-prev" type="button" data-target="#carouselCaptions" data-slide="prev">
                        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                        <span class="sr-only">Previous</span>
                      </button>
                      <button class="carousel-control-next" type="button" data-target="#carouselCaptions" data-slide="next">
                        <span class="carousel-control-next-icon" aria-hidden="true"></span>
                        <span class="sr-only">Next</span>
                      </button>
                    </div>

                    <br />
                    <div class="portfolio-caption-subheading text-muted">
                        <p>
                            <a href="https://late-night-samurai-studios.gitbook.io/vanlife-whitepaper/" target="_blank">Project Whitepaper</a>
                        </p>

                        <p>
                        Greetings fellow nomad! Welcome to a world of exploration and adventure as you traverse the
                        open world in your new life as a van-dwelling traveler.  You have taken the first step towards
                        your new lifestyle, and can't wait to see all the exciting things the world has to offer.
                        After quitting your 9-to-5 you are ready to take in all that fresh outdoor air and start anew.
                        Great job obtaining your first vehicle, now its time to set out to the local pond and spend
                        the day relaxing and fishing!
                        </p>

                        <p><b>VanLife - "Life On The Road"</b>, is a <b>Play To Earn (P2E) adventure open-world style 3D game</b>
                        &nbsp;that puts the player into the shoes of a fulltime van-dweller.  Our <b>main goal</b> is to provide the player with
                        rich realistic experiences that will first and foremost be FUN!  Our <b>general concept for core
                        gameplay</b> is the following: </p>

                        <p>
                            <u>
                                <li>Realistic fishing experiences</li>
                                <li>Camping with weenies, smores, and more</li>
                                <li>Roadtrips and open world exploration</li>
                                <li>Customizable vehicle build system</li>
                                <li>Active and passive income generation</li>
                            </u>
                            ...and much, much, more!
                        </p>

                        <p>
                            We will also be integrating <b>purely optional cryptocurrency and NFT's (Non-Fungible Tokens)</b> on
                            the Avalanche blockchain into the game. This will allow players to enhance their gameplay
                            and unlock assets for those interested in participating in cryptocurrency. However, those players
                            that do not wish to do so will still be able to enjoy almost all features of the game and will
                            not notice a major impact to any overall experiences.  We are actively working hard behind the scenes
                            towards an initial NFT release soon and roadmap information on that related project work can be
                            found here on our website, the <Link to="/nft-project">NFT Project</Link>
                            &nbsp;information page.
                        </p>

                        <p>
                            Have we <b>caught your attention</b> like a fish on a hook? No seriously.. are you hungry for more?&nbsp;
                            <b>If so</b>, check out our <a href="https://late-night-samurai-studios.gitbook.io/vanlife-whitepaper/" target="_blank">Whitepaper</a> with more in-depth information.
                        </p>

                        <p>
                            We are excited to share more details as we work towards our
                            initial alpha early access release which is planned for the <b>Epic Games Marketplace</b>. Please visit this website for updates
                            and consider joining our <a href='https://discord.gg/GgvtDQrqhs'>Discord</a> to connect
                            with us and stay up to date on the latest news. We would love to hear from you.
                        </p>

                    </div>
                </div>
            </div>
        </>
    )
}